<template>
    <div id="homepage">
        <!-- <div id="type">
            ZACHARIAH _WATSON
        </div> -->
    </div>
</template>
<style scoped>
#homepage {
    display:flex;
    align-items:center;
    justify-content:center;
    height:90%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:-1;
}
#type {
    font-size: 50px;
    width:16.5ch;
    color:#000;
    animation: type 1.5s steps(16), alt .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 1px solid;
    font-family: 'Michroma', sans-serif;
}
@keyframes type {
  from {
    width: 0
  }
}
@keyframes alt {
  50% {
    border-color: transparent
  }
}
</style>